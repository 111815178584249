


































import { AnyObject } from '@movecloser/front-core'
import { Component } from 'vue-property-decorator'

import { Iterator, MapSelector } from '../../../molecules'

import { AbstractModuleForm } from '../../_abstract'

import { tabsContentFactory } from '../Tabs.factory'
import { TabsModule, TabsModuleContent } from '../Tabs.contracts'

/**
 * TODO: Document all methods and properties.
 *
 * @author Olga Milczek <olga.milczek@movecloser.pl> (original)
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl> (edited)
 */
@Component({
  name: 'TabsModuleForm',
  components: { Iterator, MapSelector }
})
export class TabsModuleForm extends AbstractModuleForm<TabsModule> {
  protected initialContent: TabsModuleContent = tabsContentFactory()

  /**
   * Method which return new Tab to add to Tabs array.
   */
  public addNewTab (): AnyObject {
    return {
      containerId: '',
      label: ''
    }
  }
}

export default TabsModuleForm
